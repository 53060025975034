const possibleCods = ['1', '3', '4', '5', '6', '7', '10', '13']; 

function unidadeRetirada(codUnidade) {
    return codUnidade === '1'
        ? 'Objetivo Centro'
        : codUnidade === '3'
            ? 'Objetivo Leste II'
            : codUnidade === '4'
                ? 'Objetivo Centro/Sul I'
                : codUnidade === '5'
                    ? 'Objetivo Centro/Sul II'
                    : codUnidade === '6'
                        ? 'Objetivo Leste Infantil'
                        : codUnidade === '7'
                            ? 'Objetivo Leste I'
                            : codUnidade === '10'
                                ? 'Escola Expert'
                                : codUnidade === '13'
                                    ? 'Great School'
                                    : 'Happy Food';
}

function unidadeEndereco(codUnidade) {
    return codUnidade === '1'
        ? 'Av. Frei Serafim, 1989'
        : codUnidade === '3'
            ? 'Rua das Orquídeas, 988'
            : codUnidade === '4'
                ? 'Rua Firmino Pires, 787'
                : codUnidade === '5'
                    ? 'Rua Rui Barbosa, 799'
                    : codUnidade === '6'
                        ? 'Rua das Orquídeas, 191'
                        : codUnidade === '7'
                            ? 'Rua das Orquídeas, 830'
                            : codUnidade === '10'
                                ? 'Rua General Lages, 2205'
                                : codUnidade === '13'
                                    ? 'Av. Nossa Senhora de Fátima, 1000'
                                    : 'Rua das Orquídeas, 830';
}

function unidadeTelefone(codUnidade) {
    return codUnidade === '1'
        ? '(86) 3304-2526'
        : codUnidade === '3'
            ? '(86) 3215-5032'
            : codUnidade === '4'
                ? '(86) 3215-5480'
                : codUnidade === '5'
                    ? '(86) 3215-5482'
                    : codUnidade === '6'
                        ? '(86) 3215-5034'
                        : codUnidade === '7'
                            ? '(86) 3025-8551'
                            : codUnidade === '10'
                                ? '(86) 3025-0040'
                                : codUnidade === '13'
                                    ? '(86) 2222-4000'
                                    : '(86) 3025-8551';
}

export { unidadeEndereco, unidadeRetirada, unidadeTelefone, possibleCods };